var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-2 border border-radius",class:_vm.corBackground
      ? `bg-valorem-${_vm.corBackground} border-valorem-${_vm.corBackground} text-white`
      : 'border-valorem-cinza-medio bg-valorem-branco'},[_c('div',{staticClass:"d-flex align-items-center py-2"},[_c('i',{class:`
        ${_vm.icone} 
        font-18 
        p-2 
        pr-3 
        ${_vm.corBackground ? '' : 'text-valorem-azul-naval'}
      `}),_c('div',{staticClass:"d-flex flex-column"},[_c('small',{class:_vm.corBackground ? '' : 'text-valorem-cinza-escuro'},[_vm._v(_vm._s(_vm.titulo))]),_c('h6',{staticClass:"mb-0",class:_vm.corBackground ? '' : 'text-valorem-grafite'},[_vm._v(" "+_vm._s(_vm.valorFormatado)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }