export default [
  // {
  //   id: "1",
  //   icon: "crosshair",
  //   title: "Dashboard",
  //   i18n: "MENU.DASHBOARD",
  //   to: "/dashboard/classic-dashboard",
  // },
  {
    id: '2',
    title: 'Dashboard',
    i18n: 'MENU.DASHBOARD',
    permissao: null,
    to: '/dashboard',
    iconExt: require('../../assets/scss/icons/assinaturas/valorem_ciano/dashboard.svg')
  },
  {
    id: '3',
    icon: 'settings',
    title: 'Gerenciamento',
    i18n: 'MENU.GERENCIAMENTO',
    permissao: null,
    dashboard : true,
    to: '',
    children: [
      {
        id: '1',
        title: 'Clientes',
        i18n: 'MENU.CLIENTES',
        permissao: 'Cliente',
        to: '/clientes',
        iconExt: require('../../assets/scss/icons/assinaturas/valorem_ciano/cliente.svg'),
        iconeBranco: require('../../assets/scss/icons/assinaturas/valorem_branco/cliente.svg'),
      },
      {
        id: '2',
        title: 'Estabelecimentos',
        i18n: 'MENU.ESTABELECIMENTOS',
        permissao: 'Estabelecimento',
        to: '/estabelecimentos',
        iconExt: require('../../assets/scss/icons/assinaturas/valorem_ciano/estabelecimento.svg'),
        iconeBranco: require('../../assets/scss/icons/assinaturas/valorem_branco/estabelecimento.svg'),
      },
      {
        id: '3',
        title: 'Planos',
        i18n: 'MENU.PLANOS',
        permissao: 'Plano',
        to: '/planos',
        iconExt: require('../../assets/scss/icons/assinaturas/valorem_ciano/plano.svg'),
        iconeBranco: require('../../assets/scss/icons/assinaturas/valorem_branco/plano.svg'),
      },
      {
        id: '4',
        title: 'Produtos',
        i18n: 'MENU.PRODUTOS',
        permissao: 'Usuario',
        to: '/produtos',
        iconExt: require('../../assets/scss/icons/assinaturas/valorem_ciano/produtos.svg'),
        iconeBranco: require('../../assets/scss/icons/assinaturas/valorem_branco/produtos.svg'),
      },
      {
        id: '5',
        title: 'Usuários',
        permissao: 'Usuario',
        i18n: 'MENU.USUARIOS',
        iconExt: require('../../assets/scss/icons/assinaturas/valorem_ciano/usuarios.svg'),
        iconeBranco: require('../../assets/scss/icons/assinaturas/valorem_branco/usuarios.svg'),
        to: '/usuarios',
      },
      {
        id: '6',
        title: 'Grupo economico',
        permissao: 'GrupoEconomico',
        i18n: 'MENU.GRUPO_ECONOMICO',
        iconExt: require('../../assets/scss/icons/assinaturas/valorem_ciano/grupo_economico.svg'),
        iconeBranco: require('../../assets/scss/icons/assinaturas/valorem_branco/grupo_economico.svg'),
        to: '/grupo_economico',
      }
    ]
  },
  {
    id: '4',
    iconExt: require('../../assets/scss/icons/assinaturas/valorem_ciano/link_de_pagamento.svg'),
    title: 'Link de pagamento',
    i18n: 'MENU.LINK_PAGAMENTO',
    permissao: 'LinkPagamento',
    to: '/link_pagamento',
  },
  {
    id: '5',
    iconExt: require('../../assets/scss/icons/assinaturas/valorem_ciano/assinaturas.svg'),
    title: 'Assinaturas',
    i18n: 'MENU.ASSINATURAS',
    permissao: 'Assinatura',
    to: '/assinaturas',
  },
  {
    id: '6',
    iconExt: require('../../assets/scss/icons/assinaturas/valorem_ciano/relatorios.svg'),
    title: 'Extrato de transação',
    i18n: 'MENU.EXTRATO_TRANSACAO',
    permissao: 'ExtratoTransacao',
    to: '/extrato_transacao',
  },
  {
    id: '7',
    iconExt: require('../../assets/scss/icons/assinaturas/valorem_ciano/auditoria.svg'),
    title: 'Auditoria',
    i18n: 'MENU.AUDITORIA',
    permissao: 'Auditoria',
    to: '/auditoria',
  },
]
