<template>
  <div
    class="px-2 border border-radius"
    :class="
      corBackground
        ? `bg-valorem-${corBackground} border-valorem-${corBackground} text-white`
        : 'border-valorem-cinza-medio bg-valorem-branco'
    "
  >
    <div class="d-flex align-items-center py-2">
      <i
        :class="`
          ${icone} 
          font-18 
          p-2 
          pr-3 
          ${corBackground ? '' : 'text-valorem-azul-naval'}
        `"
      ></i>
      <div class="d-flex flex-column">
        <small :class="corBackground ? '' : 'text-valorem-cinza-escuro'">{{
          titulo
        }}</small>
        <h6 class="mb-0" :class="corBackground ? '' : 'text-valorem-grafite'">
          {{ valorFormatado }}
        </h6>
      </div>
    </div>
  </div>
</template>
<script>
import helpers from "@/common/utils/helpers";

export default {
  props: {
    titulo: {},
    valor: {},
    icone: {},
    corBackground: {},
  },
  computed: {
    valorFormatado: function () {
      return helpers.formatarValor(this.valor);
    },
  },
};
</script>
<style lang="scss" scoped>
.border-radius {
  border-radius: 4px;
}
</style>
