<template>
  <b-button :id="id" @click="redirecionar" variant="ligth" class="dashboard-botao">
    <div class="d-flex align-items-center">
      <div
        :class="`icone bg-${variante} d-flex align-items-center justify-content-center`"
      >
        <object :data="icone" width="20" height="20"></object>
      </div>
      <div>
        <div :class="`titulo text-${variante}`">{{ titulo }}</div>
        <div class="detalhe">{{ detalhe }}</div>
      </div>
    </div>
  </b-button>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
    },
    titulo: {
      type: String,
    },
    detalhe: {
      type: String,
    },
    icone: {},
    variante: {
      type: String,
      default: "valorem-ciano",
    },
    rota: {
      type: String,
      default: "dashboard",
    },
  },
  methods: {
    redirecionar() {
      this.$router.push(this.rota);
    },
  },
};
</script>
<style lang=""></style>
