import ApiService from "@/common/api/api.service";

const DashboardService = {
  
  buscarDados : function(filtro){
    let link = `dashboard?1=1`;
    link = link.concat(`${filtro.estabelecimentoId ? `&EstabelecimentoId=${filtro.estabelecimentoId}` : ''}`);
    link = link.concat(`${filtro.dataInicial ? `&DataInicial=${filtro.dataInicial}` : ''}`);
    link = link.concat(`${filtro.dataFinal ? `&DataFinal=${filtro.dataFinal}` : ''}`);    
    return ApiService.get(link);
  },
};

export default DashboardService;
