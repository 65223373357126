<template>
  <div>
    <b-card no-body class="mb-3">
      <div class="d-flex">
        <input-autocomplete
          class="px-3 pt-2 flex-fill"
          id="input-filtro-estabelecimento"
          :label="$t('DASHBOARD.FILTRO')"
          :options="opcoes.estabelecimentos"
          v-model="filtro.estabelecimentoId"
        />
        <div class="pr-3 d-flex align-items-end">
          <div class="mb-3">
            <b-button variant="outline-valorem-ciano" @click="filtrar">
              {{ $t("GERAL.FILTRAR") }}
            </b-button>
          </div>
        </div>
      </div>
    </b-card>
    <div class="row">
      <div class="col-12 col-md-6">
        <b-card no-body>
          <div class="px-3 pt-3">
            <div class="mb-2">{{ $t("DASHBOARD.PERIODO") }}</div>
            <div class="d-flex align-items-center">
              <input-date
                id="input-filtro-data-inicial"
                class="mr-2 flex-fill"
                v-model="filtro.dataInicial"
              />
              <h6 class="mr-2">a</h6>
              <input-date
                class="flex-fill"
                id="input-filtro-data-final"
                v-model="filtro.dataFinal"
              />
              <div class="ml-3 d-flex align-items-end">
                <div class="mb-3">
                  <b-button variant="outline-valorem-ciano" @click="filtrar">
                    {{ $t("GERAL.FILTRAR") }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="row px-3">
            <div class="col-12 mb-3">
              <item-carteira
                :titulo="$t('DASHBOARD.TOTAL_VENDAS')"
                :valor="dashboard.vendasAprovadas"
                icone="fas fa-dollar-sign"
                cor-background="azul-naval"
              />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <item-carteira
                :titulo="$t('DASHBOARD.VENDAS_AVISTA')"
                :valor="dashboard.vendasAVistaParcelado"
                icone="fas fa-dollar-sign"
              />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <item-carteira
                :titulo="$t('DASHBOARD.VENDAS_RECORRENCIA')"
                :valor="dashboard.vendasRecorrencia"
                icone="fas fa-dollar-sign"
              />
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-12 col-md-6">
        <b-card no-body class="h-100 d-flex justify-content-end">
          <div class="row px-3">
            <div class="col-12 col-md-6 mb-3">
              <item-carteira
                :titulo="$t('DASHBOARD.ASSINATURAS_ATIVAS')"
                :valor="dashboard.assinaturasAtivas"
                icone="fas fa-dollar-sign"
              />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <item-carteira
                :titulo="$t('DASHBOARD.ASSINATURAS_ANTECIPADAS')"
                :valor="dashboard.assinaturasAtivasAntecipadas"
                icone="fas fa-dollar-sign"
              />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <item-carteira
                :titulo="$t('DASHBOARD.ASSINATURAS_ATRASO')"
                :valor="dashboard.assinaturasAtivasEmAtraso"
                icone="far fa-clock"
              />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <item-carteira
                :titulo="$t('DASHBOARD.LIMITE_CREDITO_ANTECIPACAO')"
                :valor="dashboard.limiteAntecipacaoAtivo"
                icone="fas fa-hand-holding-usd"
                cor-background="verde"
              />
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <div v-for="(menu, j) in menuFiltrado" :key="`menu-dashboard-${j}`">
      <div class="h6 mt-4 mb-2">
        {{ $t(menu.i18n) }}
      </div>
      <b-card class="px-2">
        <valorem-scroll>
          <div
            v-for="(item, i) in filtrarBotoes(menu.children)"
            :key="`dashbord-botao-${i}`"
            class="w-100 w-md-20 div-scroll"
          >
            <dashboard-botao
              :id="`btn-dashboard-${menu.id}-${item.id}`"
              :icone="item.iconeBranco"
              :titulo="$t(item.i18n)"
              :detalhe="$t(`DASHBOARD.${item.i18n}.DETALHE`)"
              :rota="item.to"
            />
          </div>
        </valorem-scroll>
      </b-card>
    </div>
  </div>
</template>
<script>
import { InputAutocomplete, InputDate } from "../../components/inputs";
import DashboardBotao from "../../components/card/DashboardBotao.vue";
import helpers from "@/common/utils/helpers";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import DashboardService from "@/common/services/dashboard/dashboard.service";
import MinhaContaService from "@/common/services/usuario/minha-conta.service";
import SidebarData from "../../layouts/full-layout/SidebarData";
import ItemCarteira from "./ItemCarteira.vue";
import moment from "moment";
export default {
  components: {
    InputAutocomplete,
    InputDate,
    DashboardBotao,
    ItemCarteira,
  },
  data() {
    return {
      filtro: {
        estabelecimentoId: null,
        dataInicial: "",
        dataFinal: "",
      },
      opcoes: {
        estabelecimentos: [],
      },
      dashboard: {},
      form: {
        limiteAntecipacao: {
          icone: "globe",
          texto: "LIMITE_ANTECIPACAO",
          corValor: "ciano",
          valor: 0,
        },
        assinaturasAntecipadas: {
          icone: "clock",
          texto: "ASSINATURAS_ANTECIPADAS",
          corValor: "ciano",
          valor: 0,
        },
        limiteDisponivel: {
          icone: "globe",
          texto: "LIMITE_DISPONIVEL",
          corValor: "ciano",
          valor: 0,
        },
        assinaturasAtrasadas: {
          icone: "dollar-sign",
          texto: "ASSINATURAS_ATRASADAS",
          corValor: "ciano",
          valor: 0,
        },

        linkAvista: {
          icone: "dollar-sign",
          texto: "LINK_AVISTA",
          corValor: "grafite",
          valor: 0,
        },
        linkRecorrencia: {
          icone: "globe",
          texto: "LINK_RECORRENCIA",
          corValor: "grafite",
          valor: 0,
        },
        ticketMedio: {
          icone: "bar-chart-2",
          texto: "TICKET_MEDIO",
          corValor: "grafite",
          valor: 0,
        },
        taxaConversao: {
          icone: "percent",
          texto: "TAXA_CONVERSAO",
          corValor: "grafite",
          valor: 0,
        },
      },
      botoes: SidebarData,
    };
  },
  methods: {
    filtrar() {
      this.buscar();
    },
    buscar() {
      this.$store.dispatch(START_LOADING);
      DashboardService.buscarDados(this.filtro)
        .then((res) => {
          this.dashboard = res.data.data;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarEstabelecimentos: function () {
      this.opcoes.estabelecimentos = [];
      this.opcoes.estabelecimentos = [{ value: null, text: "Todos estabelecimentos" }];
      this.$store.dispatch(START_LOADING);
      MinhaContaService.buscarEstabelecimento()
        .then((res) => {
          this.opcoes.estabelecimentos.push(
            ...res.data.data.estabelecimentos.map((el) => {
              return {
                value: el.id,
                text: el.nome,
              };
            })
          );
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    permissao: function (permissao) {
      return helpers.validarAcesso(permissao).visualizar;
    },
    validarAcesso: function (el) {
      if (el.esconder) return false;

      if (el.permissao == null && el.children) {
        return el.children.filter((el2) => {
          return el2.permissao == null || helpers.validarAcesso(el2.permissao).visualizar;
        }).length;
      }

      return helpers.validarAcesso(el.permissao).visualizar;
    },
    filtrarBotoes: function (botoes) {
      return botoes.filter((el) => {
        return this.validarAcesso(el);
      });
    },
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [{ titulo: this.$t("DASHBOARD.TITULO") }]);
    this.filtro.estabelecimentoId = this.$store.getters["estabelecimentoSelecionado"];

    let dataAtual = moment();
    this.filtro.dataInicial = dataAtual.startOf('month').format("YYYY-MM-DD");
    this.filtro.dataFinal = dataAtual.endOf('month').format("YYYY-MM-DD");

    this.buscarEstabelecimentos();
    this.buscar();
  },
  computed: {
    botoesFiltrados: function () {
      return this.botoes
        .find((el) => el.id == 3)
        .children.filter((el) => {
          return this.permissao(el.permissao);
        });
    },
    menuFiltrado: function () {
      return this.botoes.filter((el) => {
        if (!el.dashboard) return false;

        return this.validarAcesso(el);
      });
    },
    ehAdmin: function () {
      return this.$store.getters.ehAdmin;
    },
    estabelecimentoSelecionado: function () {
      return this.$store.getters["estabelecimentoSelecionado"];
    },
  },
  watch: {
    estabelecimentoSelecionado: function () {
      this.filtro.estabelecimentoId = this.$store.getters["estabelecimentoSelecionado"];
      this.buscar();
    },
  },
};
</script>
<style></style>
